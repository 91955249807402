import React from 'react';
import { useFormikContext } from 'formik';

import CurrentFilterCard from './CurrentFilterCard';

const FilterdSelected = () => {
  const { values = {}, setFieldValue } = useFormikContext();
  const { categories, frame, pumpTypes, tankSize } = values;
  const total =
    categories.length + frame.length + pumpTypes.length + tankSize.length;

  const clearValues = field => {
    setFieldValue(field, []);
  };

  return (
    <div className="kos__FilterSelected">
      {total > 0 && (
        <div className="kos__FilterSelected__Total">
          {total} {'filters applied'}
        </div>
      )}
      <CurrentFilterCard
        data={categories}
        title="Category: "
        onClear={() => {
          clearValues('categories');
        }}
      />
      <CurrentFilterCard
        data={frame}
        title="Frames: "
        onClear={() => {
          clearValues('frame');
        }}
      />
      <CurrentFilterCard
        data={pumpTypes}
        title="Pump Types: "
        onClear={() => {
          clearValues('pumpTypes');
        }}
      />
      <CurrentFilterCard
        data={tankSize}
        title="Tank Size: "
        onClear={() => {
          clearValues('tankSize');
        }}
      />
      {total > 0 && (
        <div className="kos__FilterSelected__Clear">
          <span
            className="kos__FilterSelected__Clear__Label"
            onClick={() => {
              clearValues('categories');
              clearValues('frame');
              clearValues('pumpTypes');
              clearValues('tankSize');
            }}
          >
            {'Clear all filters'}
          </span>
        </div>
      )}
    </div>
  );
};

export default FilterdSelected;
