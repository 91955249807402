import React from 'react';

import { Formik } from 'formik';
import { graphql } from 'gatsby';
import 'rc-pagination/assets/index.css';

import FiltersForm from '../../components/Sprayers/Form';

const Sprayers = ({ data }) => {
  return (
    <Formik
      initialValues={{
        categories: [],
        frame: [],
        pumpTypes: [],
        tankSize: []
      }}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          alert(JSON.stringify(values, null, 2));
          setSubmitting(false);
        }, 400);
      }}
    >
      <FiltersForm data={data} />
    </Formik>
  );
};

export default Sprayers;

export const pageQuery = graphql`
  query SprayersList {
    sprayersList {
      list {
        compare_at_price
        available
        description
        featured_image
        handle
        type
        id
        tags {
          Frame
          Pump_Type
          SKU
          Tank_Size
        }
        title
        variants {
          sku
          title
          id
        }
      }
      categories
      tagsList
      tags {
        Frame
        Pump_Type
        Tank_Size
      }
    }
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        services {
          title
          description
          button
        }
      }
    }
  }
`;
