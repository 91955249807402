import React, { useContext } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Checkbox from '@material-ui/core/Checkbox';

import { SprayersContext } from '../../resources/Sprayers';

const GreenCheckbox = withStyles({
  root: {
    color: '#828282',
    '&$checked': {
      color: '#03A62C'
    }
  },
  checked: {}
})(props => <Checkbox color="default" {...props} />);

const Filter = ({ name, data, title }) => {
  const { setPage } = useContext(SprayersContext);
  const { values } = useFormikContext();

  return (
    <ExpansionPanel className="kos__ExpansionPanel">
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={name}
        className="kos__ExpansionPanelSummary"
        id={name}
      >
        <span>{title}</span>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <FieldArray
          name={name}
          render={arrayHelpers => {
            return (
              <div>
                {data.map(item => (
                  <div key={item}>
                    <label className="kos__ExpasionPanelLabel">
                      <GreenCheckbox
                        name={name}
                        value={item}
                        checked={values[name].includes(item)}
                        onChange={e => {
                          setPage(1);
                          if (e.target.checked) {
                            arrayHelpers.push(item);
                          } else {
                            const idx = values.categories.indexOf(item);

                            arrayHelpers.remove(idx);
                          }
                        }}
                      />
                      {item}
                    </label>
                  </div>
                ))}
              </div>
            );
          }}
        />
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default Filter;
