import React, { useContext, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Pagination from 'rc-pagination';
import { Form, useFormikContext } from 'formik';
import { Link } from 'gatsby';
import 'rc-pagination/assets/index.css';

import Layout from '../Layouts/Main';
import Filter from './Filter';
import CurrentFilter from './CurrentFilter';
import Services from '../Services/Services';

import { SprayersContext } from '../../resources/Sprayers';

const FiltersForm = ({ data }) => {
  const [sprayers, setSprayers] = useState([]);
  const [total, setTotal] = useState(0);
  const { page, perPage, setPage } = useContext(SprayersContext);
  const { values } = useFormikContext();
  const { frontmatter } = data.markdownRemark;
  const offset = (page - 1) * perPage;

  useEffect(() => {
    const filterByCategories = values.categories.length > 0;
    const filterByFrame = values.frame.length > 0;
    const filterByPumpType = values.pumpTypes.length > 0;
    const filterByTankSize = values.tankSize.length > 0;

    const filtered = data.sprayersList.list.filter(item => {
      const includesCategory = values.categories.includes(item.type);
      const includesFrame = values.frame.includes(
        item.tags.Frame && item.tags.Frame.trim()
      );
      const includesPumpType = values.pumpTypes.includes(
        item.tags.Pump_Type && item.tags.Pump_Type.trim()
      );
      const includesTankSize =
        item.tags.Tank_Size &&
        item.tags.Tank_Size.includes(values.tankSize.join('/'));

      if (
        filterByCategories &&
        filterByFrame &&
        filterByPumpType &&
        filterByTankSize
      ) {
        return (
          includesCategory &&
          includesFrame &&
          includesPumpType &&
          includesTankSize
        );
      }

      if (filterByCategories && filterByFrame && filterByPumpType) {
        return includesCategory && includesFrame && includesPumpType;
      }

      if (filterByCategories && filterByPumpType && filterByTankSize) {
        return includesCategory && includesPumpType && includesTankSize;
      }

      if (filterByCategories && filterByFrame && filterByTankSize) {
        return includesCategory && includesFrame && includesTankSize;
      }

      if (filterByFrame && filterByPumpType && filterByTankSize) {
        return includesFrame && includesPumpType && includesTankSize;
      }

      if (filterByCategories && filterByFrame) {
        return includesCategory && includesFrame;
      }

      if (filterByCategories && filterByPumpType) {
        return includesCategory && includesPumpType;
      }
      if (filterByCategories && filterByTankSize) {
        return includesCategory && includesTankSize;
      }

      if (filterByFrame && filterByPumpType) {
        return includesFrame && includesPumpType;
      }

      if (filterByFrame && filterByTankSize) {
        return includesFrame && includesTankSize;
      }

      if (filterByPumpType && filterByTankSize) {
        return includesPumpType && includesTankSize;
      }

      if (filterByTankSize) {
        return includesTankSize;
      }

      if (filterByPumpType) {
        return includesPumpType;
      }

      if (filterByFrame) {
        return includesFrame;
      }

      if (filterByCategories) {
        return includesCategory;
      }

      return true;
    });

    setSprayers(filtered.slice(offset, offset + perPage));
    setTotal(filtered.length);
  }, [values, offset]);

  return (
    <Form>
      <Layout navbarFixed blueLogo removeHeader>
        <div className="container kos__sprayers">
          <Grid container className="kos__sprayers__container">
            <Grid item xs={12} md={3} className="kos__sprayers__filtersColumns">
              <CurrentFilter />
              <Filter
                data={data.sprayersList.categories}
                name="categories"
                title="Categories"
              />
              <Filter
                data={data.sprayersList.tags.Frame}
                name="frame"
                title="Frames"
              />
              <Filter
                data={data.sprayersList.tags.Pump_Type}
                name="pumpTypes"
                title="Pump Type"
              />
              <Filter
                data={data.sprayersList.tags.Tank_Size}
                name="tankSize"
                title="Tank Size"
              />
            </Grid>
            <Grid item xs={12} md={9}>
              <h1 className="kos__sprayerCard__title">{'Sprayers'}</h1>
              <Grid container>
                {sprayers.map(sprayer => (
                  <div key={sprayer.id} className="kos__sprayerCard">
                    <div className="kos__sprayerCard__imageWrapper">
                      <img
                        className="kos__sprayerCard__img"
                        src={sprayer.featured_image}
                        alt={sprayer.title}
                      />
                    </div>
                    <div className="kos__sprayerCard__sku">
                      {`SKU: ${sprayer.variants[0] && sprayer.variants[0].sku}`}
                    </div>
                    <div className="kos__sprayerCard__content">
                      {sprayer.title}
                    </div>
                    <div className="kos__sprayerCard__footer">
                      <Link
                        className="button is-rounded kos__btn--green kos__btn--big"
                        to={`/products/${sprayer.handle}`}
                      >
                        <span>Details</span>
                        <ChevronRightIcon />
                      </Link>
                    </div>
                  </div>
                ))}
              </Grid>
              <Pagination
                total={total + 1}
                showLessItems
                prevIcon={<ArrowBackIcon className="kos__pagination__icon" />}
                nextIcon={
                  <ArrowForwardIcon className="kos__pagination__icon" />
                }
                onChange={page => {
                  setPage(page);
                }}
                current={page}
                className="kos__pagination"
                locale={{}}
              />
            </Grid>
          </Grid>
          <Services services={frontmatter.services} />
        </div>
      </Layout>
    </Form>
  );
};

export default FiltersForm;
