import React from 'react';
import CloseIcon from '@material-ui/icons/Close';

const CurrentFilterCard = ({ data = [], title, onClear }) => {
  return (
    data.length > 0 && (
      <div className="kos__FilterSelected__Card">
        <div style={{ flex: 1 }}>
          <span className="kos__FilterSelected__Title">{title}</span>
          {data.map((filter, i, arr) => {
            if (arr.length - 1 === i) {
              return (
                <span key={filter} className="kos__FilterSelected__Content">
                  {filter}
                </span>
              );
            } else {
              return (
                <span key={filter} className="kos__FilterSelected__Content">
                  {filter}
                  {', '}
                </span>
              );
            }
          })}
        </div>
        <div className="kos__FilterSelected__CloseWrapper">
          <CloseIcon className="kos__FilterSelected__Close" onClick={() => onClear()} />
        </div>
      </div>
    )
  );
};

export default CurrentFilterCard;
